import React from 'react'
import Gallery from './Gallery'

import dashpro1 from './../assets/images/portfolio/dashpro/dashboard.png'
import dashpro2 from './../assets/images/portfolio/dashpro/customizableTiles.png'
import dashpro3 from './../assets/images/portfolio/dashpro/customizableTiles.png'
import dashpro4 from './../assets/images/portfolio/dashpro/personalization.png'
import dashpro5 from './../assets/images/portfolio/dashpro/transactions.png'
import dashpro6 from './../assets/images/portfolio/dashpro/transfer.png'

import organator1 from './../assets/images/portfolio/organator/organator1.png'
import organator2 from './../assets/images/portfolio/organator/organator2.png'
import organator3 from './../assets/images/portfolio/organator/organator3.png'
import organator4 from './../assets/images/portfolio/organator/organator4.png'
import organator5 from './../assets/images/portfolio/organator/organator5.png'
import organator6 from './../assets/images/portfolio/organator/organator6.png'
import organator7 from './../assets/images/portfolio/organator/organator7.png'

import battleField1 from './../assets/images/portfolio/battleField/home.jpg'

import fitbot1 from './../assets/images/portfolio/fitbot/fitbot1.png'
import fitbot2 from './../assets/images/portfolio/fitbot/fitbot2.png'
import fitbot3 from './../assets/images/portfolio/fitbot/fitbot3.png'
import fitbot4 from './../assets/images/portfolio/fitbot/fitbot4.png'

const DASHPRO_IMAGES = [
    { id: '1', src: dashpro1, thumbnail: dashpro1, caption: 'Photo 1', description: 'Dashpro Finanical Dashboard'},
    { id: '2', src: dashpro2, thumbnail: dashpro2, caption: 'Photo 2', description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'},
    { id: '3', src: dashpro3, thumbnail: dashpro3, caption: 'Photo 3', description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'},
    { id: '4', src: dashpro4, thumbnail: dashpro4, caption: 'Photo 4', description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'},
    { id: '5', src: dashpro5, thumbnail: dashpro5, caption: 'Photo 5', description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'},
    { id: '6', src: dashpro6, thumbnail: dashpro6, caption: 'Photo 6', description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'}
];

const ORGANATOR_IMAGES = [
    { id: '1', src: organator1, thumbnail: organator1, caption: 'Photo 1', description: 'Organator'},
    { id: '1', src: organator2, thumbnail: organator2, caption: 'Photo 2', description: 'Organator'},
    { id: '1', src: organator3, thumbnail: organator3, caption: 'Photo 3', description: 'Organator'},
    { id: '1', src: organator4, thumbnail: organator4, caption: 'Photo 4', description: 'Organator'},
    { id: '1', src: organator5, thumbnail: organator5, caption: 'Photo 5', description: 'Organator'},
    { id: '1', src: organator5, thumbnail: organator6, caption: 'Photo 6', description: 'Organator'},
    { id: '1', src: organator7, thumbnail: organator7, caption: 'Photo 7', description: 'Organator'},
];

const BATTLEFIELD_IMAGES = [
    { id: '1', src: battleField1, thumbnail: battleField1, caption: 'Photo 1', description: 'BattleField'},
];

const FITBOT_IMAGES = [
    { id: '1', src: fitbot1, thumbnail: fitbot1, caption: 'Photo 1', description: 'FitBot'},
    { id: '1', src: fitbot2, thumbnail: fitbot2, caption: 'Photo 2', description: 'FitBot'},
    { id: '1', src: fitbot3, thumbnail: fitbot3, caption: 'Photo 3', description: 'FitBot'},
    { id: '1', src: fitbot4, thumbnail: fitbot4, caption: 'Photo 4', description: 'FitBot'},
];

const Portfolio = (props) => {
    return (
            <section id="two">
                <h2>Recent Work</h2>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: ' space-between'}}>
                    <div id='portfolio-item'>
                        <Gallery 
                            images={DASHPRO_IMAGES}
                            src={dashpro1}
                            thumbnail={dashpro1}
                            caption='Dashpro'
                            description='Dashpro is a web application which enables companies to have an overview of thier business with 
                            a dashboard, manage transactions and perform transfers'
                            moreInfo={
                                <div>
                                    React<b>frontend</b><br/>
                                    Materual UI <b>styling</b><br/>
                                    Node.js <b>backend</b><br/>
                                    Firebase <b>user authentication</b><br/>
                                    AWS Key Management Service <b>key Management</b><br/>
                                    Dynamodb (NoSQL) <b>database</b><br/>
                                </div>
                            }
                            link='https://dashpro-mtn.herokuapp.com'
                        />    
                    </div>

                    <div id='portfolio-item'>
                        <Gallery 
                            images={FITBOT_IMAGES}
                            src={fitbot1}
                            thumbnail={fitbot1}
                            caption='FitBot'
                            description='FitBot is an all-in-one mobile fitness companion desgined to guide your clients through their workout'
                            link='https://github.com/algonquin-chatbot/Fitbot-LandingPage'
                            moreInfo={
                                <div>
                                    Ionic Framework<b>frontend</b><br/>
                                    Node.js <b>backend</b><br/>
                                    Firebase <b>database</b><br/>
                                </div>
                            }
                        />    
                    </div>    
                    
                    <div id='portfolio-item'>
                        <Gallery 
                            images={ORGANATOR_IMAGES}
                            src={organator1}
                            thumbnail={organator1}
                            caption='Organator'
                            description='Organator is a multi-platform developed mobile application which promotes Organ and Tissue donation. 
                            It has a donor and hospital type of account which has different a unique view with different functionalities'
                            link='https://www.youtube.com/watch?v=o42-WAprNyk'
                            moreInfo={
                                <div>
                                    Ionic Framework<b>frontend</b><br/>
                                    Node.js <b>backend</b><br/>
                                    Auth0 <b>user authentication</b><br/>
                                    Firebase <b>database</b><br/>
                                </div>
                            }
                        />    
                    </div>  

                    <div id='portfolio-item'>
                        <Gallery 
                            images={BATTLEFIELD_IMAGES}
                            src={battleField1}
                            thumbnail={battleField1}
                            caption='BattleField Simulator'
                            description='A JavaFX designed game which uses Object-Oriented Programming, 
                            Serialization and Multi-Threading to simulate battles between two sides of army allegiance'
                            link='https://github.com/elimt/BattleFieldSimulator'
                            moreInfo={
                                <div>
                                    JavaFX <b>frontend</b><br/>
                                    Java <b>backend</b><br/>
                                </div>
                            }
                        />    
                    </div>  
                </div>
            </section>
    )
}

export default Portfolio
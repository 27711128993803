import React from 'react'
import PortfolioComp from '../components/Portfolio'
import Layout from '../components/layout'

function Portfolio(props) {

    return (
        <Layout selected='portfolio'>
            <PortfolioComp/>
        </Layout>
    )
}

export default Portfolio
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Lightbox from 'react-images';

class Gallery extends Component {
    constructor () {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
            expanded: false
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.gotoImage = this.gotoImage.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    }

    openLightbox (index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox () {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious () {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext () {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    gotoImage (index) {
        this.setState({
            currentImage: index,
        });
    }
    handleClickImage () {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }
    renderGallery () {
        const { images } = this.props;

        if (!images) return;

        const gallery = images.map((obj, i) => {
            return (
                <div className="6u 12u$(xsmall) work-item" key={i}>
                <Card>
                <div>
                <CardMedia>
                    <article>
                    <a
                        className="image fit thumb"
                        href={obj.src}
                        onClick={(e) => this.openLightbox(0, e)}
                    >
                        <img src={obj.thumbnail} alt="thumbnail"/>
                    </a>
                    </article>
                </CardMedia>
                <CardContent>
                        <h3>{obj.caption}</h3>
                        <p>{obj.description}</p>
                    </CardContent>
                    <IconButton aria-label="Add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="Share">
                        <ShareIcon />
                    </IconButton>
                    </div>
                </Card>
                </div>
            );
        });

        return (
            <div className="row">
                {gallery}
            </div>
        );
    }
    render () {
        return (
            <div>
                {/* {this.renderGallery()} */}
                <div className="6u 12u$(xsmall) work-item" style={{paddingLeft: '20px', width: '100%'}}>
                <Card>
                <div>
                <CardMedia>
                    <article>
                    <a
                        className="image fit thumb"
                        href={this.props.src}
                        onClick={(e) => this.openLightbox(0, e)}
                    >
                        <img src={this.props.thumbnail} style={{objectFit: 'contain', height: '200px'}} alt="thumbnail"/>
                    </a>
                    </article>
                </CardMedia>
                <CardContent style={{color: 'black', fontSize: '1.2em'}}>
                        <h3>{this.props.caption}</h3>
                        <p>{this.props.description}</p>
                    </CardContent>
                    <div>
                        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="Share">
                            <ShareIcon />
                        </IconButton>
                        </a>
                        <IconButton
                            style={{float: 'right'}}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                            aria-label="Show more"
                        >
                        
                            {
                                this.state.expanded 
                                ? <ExpandLessIcon />
                                : <ExpandMoreIcon />
                            }
                        </IconButton>
                    </div>
                    </div>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph>Tools:</Typography>
                        <Typography paragraph>
                        {this.props.moreInfo}
                        </Typography>
                    </CardContent>
                    </Collapse>
                </Card>
                </div>
                <Lightbox
                    currentImage={this.state.currentImage}
                    images={this.props.images}
                    isOpen={this.state.lightboxIsOpen}
                    onClickImage={this.handleClickImage}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    onClickThumbnail={this.gotoImage}
                    onClose={this.closeLightbox}
                />
            </div>
        );
    }
}

Gallery.displayName = 'Gallery';
Gallery.propTypes = {
    images: PropTypes.array,
    src: PropTypes.any,
    thumbnail: PropTypes.any,
    caption: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    moreInfo: PropTypes.any
};

export default Gallery;
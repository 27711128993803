import React from 'react'
import Helmet from 'react-helmet'
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import TrendingUp from '@material-ui/icons/TrendingUp';
import Person from '@material-ui/icons/PersonRounded';
import Web from '@material-ui/icons/WebRounded';
import Stars from '@material-ui/icons/StarsRounded';
import Assignment from '@material-ui/icons/AssignmentRounded';
import avatar from '../assets/images/elimAvatar.jpg'
import '../assets/scss/main.scss'
import drawerImage from '../assets/images/bg.jpg';
import resume from '../assets/pdf/Elim-Tsiagbey-Resume.pdf';
import { navigate } from '@reach/router';

const drawerWidth = 340;

const styles = theme => ({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      },
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        display: 'none'
      },
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundImage: `url(${drawerImage})`,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
    },
    bigAvatar: {
        margin: '0 auto',
        width: 120,
        height: 120,
    },
    selected: {
        color: 'lightyellow'
    }
  });
  
class Layout extends React.Component {
    state = {
        mobileOpen: false,
        selected: 'aboutMe'
    };

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    render() {
        const { children, theme, classes, selected } = this.props

        const drawer = (
            <div>
              <div className={classes.toolbar} />
            <div style={{margin: '0px auto', color: 'lightyellow', display: 'table'}}>
                <Avatar alt='Elim Tsiagbey' src={avatar} className={classes.bigAvatar} />
                <h1 style={{margin: 0}}><strong style={{margin: 0, color: 'lightyellow'}}>Elim Tsiagbey</strong><br /></h1>
                <div style={{margin: '0 auto', display: 'table'}}>Software Developer<br /></div>
                <div style={{margin: '0 auto', display: 'table', paddingTop: '20px'}}>
                    <ul className="icons">
                        <li><a href="https://www.linkedin.com/in/elimt/" target="_blank" rel="noopener noreferrer" style={{color: 'lightyellow'}} className="icon fa-linkedin"><span className="label">Linkedin</span></a></li>
                        <li><a href="mailto:elimty02@gmail.com" target="_blank" rel="noopener noreferrer" style={{color: 'lightyellow'}} className="icon fa-envelope-o"><span className="label">Email</span></a></li>
                    </ul>
                </div>
            </div>
              <Divider style={{marginBottom: '10px'}}/>
              <List component='nav'>
                <ListItem 
                button
                selected={selected === 'aboutMe'}
                onClick={() => navigate('/aboutMe')}>
                    <ListItemIcon><Person style={{color: 'teal'}}/></ListItemIcon>
                    <ListItemText primary='About Me' classes={{ primary: classes.selected }}  />
                </ListItem>
                <ListItem 
                button
                selected={selected === 'experience'}
                onClick={() => navigate('/experience')}>
                    <ListItemIcon><TrendingUp style={{color: 'darkmagenta'}}/></ListItemIcon>
                    <ListItemText primary='Experience' classes={{ primary: classes.selected }} />
                </ListItem>
                <ListItem 
                button
                selected={selected === 'portfolio'}
                onClick={() => navigate('/portfolio')}>
                    <ListItemIcon><Web style={{color: 'burlywood'}}/></ListItemIcon>
                    <ListItemText primary='Portfolio' classes={{ primary: classes.selected }} />
                </ListItem>
                <ListItem 
                button
                selected={selected === 'awards'}
                onClick={() => navigate('/awards')}>
                    <ListItemIcon><Stars style={{color: 'greenyellow'}}/></ListItemIcon>
                    <ListItemText primary='Awards' classes={{ primary: classes.selected }} />
                </ListItem>
                <a href={resume} target="_blank" rel="noopener noreferrer">
                    <ListItem button>
                            <ListItemIcon><Assignment style={{color: 'lavenderblush'}}/></ListItemIcon>
                                <ListItemText primary='Resume' classes={{ primary: classes.selected }}/>
                    </ListItem>
                </a>
              </List>
            </div>
          );

        return (
            <div>
                <Helmet>
                        <title>Elim Tsiagbey Portfolio</title>
                        <meta name='description' content='Portfolio' />
                </Helmet>
                
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar position='fixed' className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                        color='inherit'
                        aria-label='Open drawer'
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                        >
                        <MenuIcon />
                        </IconButton>
                        <Typography variant='overline' color='inherit'>
                            Elim Tsiagbey | Software Developer
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation='css'>
                        <Drawer
                        container={this.props.container}
                        variant='temporary'
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        >
                        {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation='css'>
                        <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant='permanent'
                        open
                        >
                        {drawer}
                        </Drawer>
                    </Hidden>
                    </nav>
                    <main className={classes.content}>
                    <div className={classes.toolbar} />
                        {children}
                        </main>
                </div>
                
                    
                
            </div>
        )
    }
}

Layout.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    container: PropTypes.object,
    selected: PropTypes.string
  };

export default withStyles(styles, { withTheme: true })(Layout)
